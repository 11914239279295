// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/Biotif_Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/assets/fonts/Biotif_Medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,\nhtml {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\n@font-face {\n  font-family: \"Biotif\";\n  font-weight: 400;\n  src: local(\"Biotif\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Biotif\";\n  font-weight: 500;\n  src: local(\"Biotif\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");\n}\n\n* {\n  font-family: \"Biotif\";\n  font-size: 16px;\n  box-sizing: border-box;\n}\n\n.pageContainer {\n  width: 100vw;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  background: #1e1e1e;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB;8DAC6D;AAC/D;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB;8DAC+D;AACjE;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["body,\nhtml {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\n@font-face {\n  font-family: \"Biotif\";\n  font-weight: 400;\n  src: local(\"Biotif\"),\n    url(../src/assets/fonts/Biotif_Book.otf) format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Biotif\";\n  font-weight: 500;\n  src: local(\"Biotif\"),\n    url(../src/assets/fonts/Biotif_Medium.otf) format(\"opentype\");\n}\n\n* {\n  font-family: \"Biotif\";\n  font-size: 16px;\n  box-sizing: border-box;\n}\n\n.pageContainer {\n  width: 100vw;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  background: #1e1e1e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
